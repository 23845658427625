import config from "../../config";


const Trip = {

  getTripList:{
    url: `/admin/trip`,
    method: "GET",
  },
  addTrip:{
    url: `/admin/addTrip`,
    method: "POST",
  },
  tripStatus :(id,queryparam)=>({
    url: `/admin/trip/${id}?status=${queryparam?.status}`,
    method: "PUT",
  }),

  editTrip:{
    url: `/admin/trip`,
    method: "POST",
  },
  userStatus :(id)=>({
    url: `/admin/trip/${id}`,
    method: "PATCH",
  }),
  deleteTrip :(id)=>({
    url: `/admin/trip/${id}`,
    method: "DELETE",
  }),
  addStudyKit:{
    url: `/admin/studyKit`,
    method: "POST",
  },
  getStudyKit: (id) =>({
    url: `/admin/studyKit/${id}`,
    method: "GET",
  }),
  deleteStudyKit: (id) =>({
    url: `/admin/studyKit/${id}`,
    method: "DELETE",
  }),

// Trip details page endpoints
  tripDetailList: (id)=>({
    url: `/admin/trip-information/${id}`,
    method: "GET",
  }),

  addTripPlanDetail:{
    url: `/admin/programPlan`,
    method: "POST",
  },
  deleteTripPlanDetail:(id) =>({
    url: `/admin/programPlan/${id}`,
    method: "DELETE",
  }),

  // Accommodation Endpoints
  addEditAccommodation:{
    url: `/admin/accommodation`,
    method: "POST",
  },

  deleteTripAccommodation:(id) =>({
    url: `/admin/accommodation/${id}`,
    method: "DELETE",
  }),

  // Transportation Endpoints
  editTransportation : (id)=>({
    url: `/admin/transportation/${id}`,
    method: "PUT",
  }),
  addTransportation:{
    url: `/admin/transportation`,
    method: "POST",
  },

  deleteTripTransportation:(t_id,type,id) =>({
    url: `/admin/transportation/${t_id}/${type}/${id}`,
    method: "DELETE",
  }),


  // Document's endpoint's

  addEditDocument:{
    url: `/admin/document`,
    method: "POST",
  },
  getdocument:(id) => ({
    url: `/admin/document?tripId=${id}`,
    method: "GET",
  }),
  
  deletedocument:(id) =>({
    url: `/admin/document/${id}`,
    method: "DELETE",
  }),
  getTransaction:{
    url: `/admin/transaction`,
    method: "GET",
  },

  //Get feedback data
  getFeedback : {
    url: `/admin/feedback`,
    method: "GET",
  },

  //Get enrolment data
  getEnrolment : {
    url: `/admin/enrolment`,
    method: "GET",
  },

  //verify_doc
  verifydocument : {
    url: `/admin/verify_doc`,
    method: "POST",
  }

};
export default Trip;
